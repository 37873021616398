import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
// import styled from "@emotion/styled";
// import tw from "twin.macro";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import Attorneys from "../components/Repeating/Attorneys";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";

import ButtonSolid from "../components/Button/ButtonSolid";

export const query = graphql`
  query LocationTemplateQuery($id: String!) {
    page: sanityLocation(id: { eq: $id }) {
      id
      seoTitle
      metaDescription
      openGraphImage {
        asset {
          url
        }
      }
      twitterCardImage {
        asset {
          url
        }
      }
      heroImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 1376)
        }
      }
      heroHeading
      _rawHeroText(resolveReferences: { maxDepth: 5 })
      address {
        text
        href
        newTab
      }
      phoneNumber {
        text
        href
      }
      _rawTextBlock1(resolveReferences: { maxDepth: 5 })
      _rawTextBlock2(resolveReferences: { maxDepth: 5 })
      _rawTextBlock3(resolveReferences: { maxDepth: 5 })
      valueProps {
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 54)
          }
        }
        title
      }
      sidebar {
        navHeading
        specialties {
          title
          link
        }
        ctaHeading
      }
    }
  }
`;

const PracticeAreasTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;

  return (
    <Layout>
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {page && (
        <SearchEngineOptimization
          title={page.seoTitle}
          description={page.metaDescription}
          openGraphImage={page.openGraphImage && page.openGraphImage.asset.url}
          twitterOpenGraphImage={
            page.twitterCardImage && page.twitterCardImage.asset.url
          }
        />
      )}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {page && (
        <>
          <section className="md:pt-8 pb-16 md:pb-20">
            <div className="container">
              <div className="grid md:grid-cols-2 gap-y-10 gap-x-10 lg:gap-x-20 items-center">
                <div className="order-2 md:order-1">
                  <div className="bg-gray-900 text-gray-300 uppercase px-2.5 py-1.5 inline-flex items-center justify-center mb-5 md:mb-6">
                    since 1989
                  </div>
                  <h1>{page.heroHeading}</h1>

                  {page._rawHeroText && (
                    <PortableText blocks={page._rawHeroText} />
                  )}

                  <div className="space-y-4 mb-6">
                    {page.address && (
                      <div className="flex items-start">
                        <i className="fas fa-map-marker-alt text-xl mr-2.5 text-primary-600"></i>

                        {page.address.newTab ? (
                          <a
                            href={page.address.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary-600 hover:text-primary-50 font-semibold"
                          >
                            {page.address.text}
                          </a>
                        ) : (
                          <a href={page.address.href}>{page.address.text}</a>
                        )}
                      </div>
                    )}

                    {page.phoneNumber && (
                      <div className="flex items-start">
                        <i className="fas fa-phone-alt text-xl mr-2.5 text-primary-600"></i>
                        <a
                          href={page.phoneNumber.href}
                          className="text-primary-600 hover:text-primary-50 font-semibold"
                        >
                          {page.phoneNumber.text}
                        </a>
                      </div>
                    )}
                  </div>

                  <ButtonSolid
                    modal="modal-contact"
                    text="Contact Us"
                    className="w-full md:w-auto"
                  />
                </div>
                <div className="order-1 md:order-2 relative md:h-full -mx-4 md:mx-0">
                  {page.heroImage && (
                    <GatsbyImage image={page.heroImage.asset.gatsbyImageData} />
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="relative pb-20 md:pb-32">
            <div className="container">
              <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
                <div className="md:col-start-1 md:col-span-8">
                  {page._rawTextBlock1 && (
                    <div className="mb-8 md:mb-10">
                      <PortableText blocks={page._rawTextBlock1} />
                    </div>
                  )}

                  {page.valueProps.length > 0 && (
                    <div className="space-y-5 mb-10">
                      {page.valueProps.map((item, i) => {
                        return (
                          <div
                            className="flex items-center space-x-3.5 md:space-x-5"
                            key={i}
                          >
                            <GatsbyImage
                              image={item.image.asset.gatsbyImageData}
                              className="min-w-[54px]"
                            />
                            <div className="md:text-xl font-normal text-secondary-50">
                              {item.title}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {page._rawTextBlock2 && (
                    <div className="mb-14 md:mb-20">
                      <PortableText blocks={page._rawTextBlock2} />
                    </div>
                  )}

                  {page._rawTextBlock3 && (
                    <div className="mb-0">
                      <PortableText blocks={page._rawTextBlock3} />
                    </div>
                  )}

                  <ButtonSolid
                    modal="modal-contact"
                    text="Contact Us"
                    className="hidden md:inline-flex"
                  />
                </div>

                <div className="md:col-end-13 md:col-span-4 md:max-w-[300px] md:ml-auto">
                  <div className="md:top-32 md:sticky">
                    {page.sidebar && page.sidebar.navHeading && (
                      <p className="text-xl font-normal text-secondary-50">
                        {page.sidebar.navHeading}
                      </p>
                    )}
                    {page.sidebar && page.sidebar.specialties && (
                      <ul className="mb-16 space-y-2">
                        {page.sidebar.specialties.map((item, i) => {
                          return (
                            <li
                              key={i}
                              className="text-sm font-bold inline-flex w-full"
                            >
                              <AniLink
                                fade
                                to={item.link}
                                className={`text-primary-600 hover:text-primary-50 no-underline flex items-center
                              }`}
                              >
                                {item.title}
                              </AniLink>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                    {page.sidebar && page.sidebar.ctaHeading && (
                      <p className="text-xl font-normal text-secondary-50">
                        {page.sidebar.ctaHeading}
                      </p>
                    )}
                    <ButtonSolid
                      modal="modal-contact"
                      text="Contact Us"
                      className="w-full md:w-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div
            style={{
              background:
                "linear-gradient(5.55deg, #26272B 16.02%, #838A9D 87.03%)",
            }}
          >
            <Testimonials />
            <Attorneys />
          </div>
   
          <RecentBlogPosts />
          <CallToAction />
        </>
      )}
    </Layout>
  );
};

export default PracticeAreasTemplate;
